/* eslint-disable jsx-a11y/alt-text */
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Col, Container, Row } from "reactstrap";
import { firestore, storage } from "./firebase";
import { Blogpost } from "./Types";
import trennlinie from "./img/line-headline.svg";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { useHistory } from "react-router-dom";
import { MouseEventHandler } from "react";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const Blog: React.FC<{ setName: (name: string) => void }> = ({ setName }) => {
  setName("Media");

  const [blogposts] = useCollectionDataOnce<Blogpost>(
    firestore
      .collection("/blogpost")
      .where("publischDate", "<", tomorrow)
      .orderBy("publischDate", "desc")
  );

  const BlogpostTeasers: React.FC<{ blogpost: Blogpost }> = ({ blogpost }) => {
    const [imageUrl] = useDownloadURL(storage.ref(blogpost.picturePath));

    const history = useHistory();

    const onClick: MouseEventHandler<HTMLDivElement> = (e: any) => {
      history.push(`/s/blog/b/${blogpost.id}`);
    };

    return (
      <Col sm="12" onClick={onClick} style={{ cursor: "pointer" }}>
        <h3>
          {" "}
          <img src={trennlinie} className="svg-icon svg-baseline " />{" "}
          {blogpost.title}
        </h3>
        <h5>
          {blogpost.publischDate?.toDate().toLocaleDateString("de-DE")} -{" "}
          <i>{blogpost.author}</i>{" "}
        </h5>

        {imageUrl && (
          <img
            src={imageUrl}
            style={{ height: "200px", width: "100%", objectFit: "cover" }}
          ></img>
        )}

        <div dangerouslySetInnerHTML={{ __html: blogpost.teaser }}></div>
      </Col>
    );
  };

  return (
    <Container>
      <Row>
        <h1>
          {""}
          <img src={trennlinie} className="svg-icon svg-baseline " /> Media
        </h1>
        {blogposts &&
          blogposts
            .filter((b) => b.online)
            .map((blogpost) => (
              <BlogpostTeasers blogpost={blogpost}></BlogpostTeasers>
            ))}
      </Row>
      <h2>
        {""}
        <img src={trennlinie} className="svg-icon svg-baseline " /> Podcast
      </h2>

      <p>Kommt bald...</p>
      {/* <iframe
        style={{ borderRadius: "12px" }}
        src="https://open.spotify.com/embed/show/6SgAvOJ2kOpswpe0aOMgDH?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe> */}
      <h2>
        {""}
        <img src={trennlinie} className="svg-icon svg-baseline " /> Youtube
      </h2>
      <p>Kommt bald...</p>
      {/* <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/R2iKRo-FhGE?si=CrMtSzxH6Z_gWM0Z"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe> */}
    </Container>
  );
};

export default Blog;
