import { Col, Container, Row } from "reactstrap";

const Impressum: React.FC<{ setName: (name: string) => void }> = ({
  setName,
}) => {
  setName("Impressum");

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h3>
            Anschrift<strong>:</strong>
          </h3>
          <p>
            Stimme X e. V. Zeitgenössisches Musiktheater Norddeutschland
            <br />
            c/o Abbildungszentrum
            <br />
            Arnoldstr. 26 - 30
            <br />
            22769 Hamburg
          </p>

          <p>
            Gerichtsstand ist Hamburg (Amtsgericht Hamburg):
            <br />
            Registernummer: VR 24879
          </p>
          <h3>Kontakt:</h3>
          <p>
            Telefon: +49 163 3114215
            <br />
            E-Mail: <a href="mailto:kontakt@stimmex.de">kontakt@stimmex.de</a>
          </p>

          <h3>Vertretungsberechtigter Vorstand:</h3>
          <p>
            Vendula Nováková
            <br />
            Iman Jesmi
            <br />
            Hans-Jörg Kapp
          </p>
          <h3>Design und Programmierung:</h3>
          <p>
            David Benjamin Schulz
            <br />
            Federico Ardila Biela
          </p>
          <h3>Hinweise zur Website:</h3>
          <p>
            Information gemäß § 36 VSBG:
            <br />
            Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die
            alternative Streitbeilegung in Verbrauchersachen) erklären die
            Betreiber*innen dieser Website:
          </p>
          <p>
            Wir sind weder bereit noch verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Impressum;
